<template>
  <div class="sub-container flex-sb">
    <div class="content col" style="width:14%;">
      <section class="section">
        <h2 class="page-title">컨텐츠 관리</h2>
        <div class="contents-menu-box">
          <h3 class="contents-menu-title">
            <a href="#/notice">공지사항 / 이벤트</a>
          </h3>
          <h3 class="contents-menu-title"><a href="#/banner">배너</a></h3>
          <h3 class="contents-menu-title"><a href="#/fund">모금자리</a></h3>
          <h3 class="contents-menu-title">
            <a href="#/health-column">건강칼럼</a>
          </h3>
          <h3 class="contents-menu-title"><a href="#/faq">FAQ</a></h3>
          <h3 class="contents-menu-title">
            <a href="#/support">지원과목 / 지원항목</a>
          </h3>
        </div>
      </section>
    </div>
    <div class="content col" style="width:86%;">
      <section class="section">
        <h3 class="section-title" style="margin-top:24px;">
          {{ contentsTitle }}
        </h3>
        <div style="padding-top: 36px;"></div>
        <div class="table-util">
          <div class="util-start">
            <button class="btn-del-select" @click="deleteSelected()">
              <span class="txt">선택항목삭제</span>
            </button>
            <span class="us-txt">검색결과: {{ total }}건</span>
          </div>
          <div class="util-end">
            <div class="tue-select select-wrap">
              <select v-model="param.pageSize" @change="list">
                <option value="10">10개씩 보기</option>
                <option value="20">20개씩 보기</option>
                <option value="30">30개씩 보기</option>
                <option value="50">50개씩 보기</option>
              </select>
            </div>
          </div>
        </div>
        <table class="tbl-list-default application-mng">
          <colgroup>
            <col class="col01" />
            <col class="col02" />
            <col class="col15" />
            <col class="col13" />
            <col class="col02" />
            <col class="col14" />
            <col class="col14" />
            <col class="col05" />
            <col class="col08" />
          </colgroup>
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  ref="check-all"
                  v-model="allCheckValue"
                  value="1"
                  id="chkAll"
                  @click="clickAllCheckbox()"
                  class="checkbox"
                />
                <label for="chkAll" class="is-blind">전체선택</label>
              </th>
              <th>번호</th>
              <th>상태</th>
              <th>제목</th>
              <th>목표액</th>
              <th>기간</th>
              <th>주소</th>
              <th>등록자</th>
              <th>등록일</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="row in data"
              v-bind:key="row.fundSeatId"
              style="cursor: pointer"
            >
              <td>
                <input
                  type="checkbox"
                  :id="'checkbox_' + row.fundSeatId"
                  v-model="deleteArray"
                  :value="row.fundSeatId"
                  class="checkbox"
                />
                <label :for="'checkbox_' + row.fundSeatId" class="is-blind"
                  >선택</label
                >
              </td>
              <td @click="open(row.fundSeatId)">
                {{ row.fundSeatId }}
              </td>

              <td @click="open(row.fundSeatId)">
                {{ row.status == 'fundraising' ? "모금중" : "종료" }}
              </td>
              <td @click="open(row.fundSeatId)">
                {{ row.title }}
              </td>
              <td @click="open(row.fundSeatId)">
                {{ row.targetAmount }} 원
              </td>
              <td @click="open(row.fundSeatId)">{{ row.always == true ? "항상" : row.start.substring(0, 16).replace("T", " ") + " ~ " + row.end.substring(0, 16).replace("T", " ") }}</td>
              <td
                style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;"
                @click="open(row.fundSeatId)"
              >
                {{row.url}}
              </td>
              <td  @click="open(row.fundSeatId)">{{ row.name}}</td>
              <td @click="open(row.fundSeatId)">
                {{ row.createdAt.substring(0, 16).replace("T", " ") }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="table-foot clearfix">
          <Pagination
            location="notice"
            ref="pagination"
            :page="nowPage"
            :page-size="param.pageSize"
          ></Pagination>
          <button class="btn-confirm tf-btn" @click="add()">
            <span class="txt ico apply-w">{{ contentsTitle }} 등록</span>
          </button>
        </div>
      </section>
    </div>
  </div>
</template>
<style></style>
<script>
import Pagination from "../components/Pagination";
export default {
  name: "FundSeat",
  components: { Pagination },
  data: () => ({
    contentsType: "fund",
    contentsTitle: "모금자리",
    param: {
      page: 0,
      pageSize: 10,
      start: null,
      end: null,
      keyword: null
    },
    keyword: null,
    start: null,
    end: null,
    nowPage: 1,
    total: 0,
    data: [],
    deleteArray: [],
    allCheckValue: []
  }),
  mounted() {
    if (this.$route.query.pageSize == undefined) {
      this.param.pageSize = 10;
    } else {
      this.param.pageSize = this.$route.query.pageSize;
    }
  },
  beforeMount() {
    this.contentsTitle = "모금자리";

    if (this.$route.query.page != "" && this.$route.query.page != null) {
      this.nowPage = this.$route.query.page;
    } else {
      this.nowPage = 1;
    }
    this.list();
  },

  methods: {
    add() {
      this.$router.push({ path: "fund/new" });
    },
    open(id) {
      this.$router.push({ path: "fund/edit/" + id });
    },
    request() {
      this.param.page = this.nowPage - 1;
      this.param.keyword = this.keyword;
      this.param.start = this.start;
      this.param.end = this.end;
      return this.$axios.get("funds", { params: this.param });
    },
    async list() {
      const getListPromise = await this.request();
      const [response] = await Promise.all([getListPromise]);

      if (response.data.success) {
        this.data = response.data.data;
        this.total = response.data.total;
        this.$refs["pagination"].setTotal(this.total);
        console.log("#####", this.total);
      }
    },
    clickAllCheckbox() {
      if (this.deleteArray.length == this.param.pageSize) {
        this.deleteArray = [];
      } else {
        this.deleteArray = [];
        for (var i = 0; i < this.data.length; i++) {
          this.deleteArray.push(this.data[i].fundSeatId);
        }
      }
    },
    deleteSelected() {
      if (confirm("선택한 항목을 삭제하시겠습니까?")) {
        var ids = this.deleteArray.join(",");

        this.$axios
          .delete("fund", { params: { ids: ids } })
          .then(response => {
            if (response.data.success) {
              this.$alert.show(response.data.message);
              this.list();
              this.deleteArray = [];
              this.allCheckValue = [];
            } else {
              this.$alert.show(response.data.message);
            }
          })
          .catch(ex => {
            if (ex.response.data.message.constructor == Object) {
              this.$alert.show(ex.response.data.message);
            }
          });
      }
    }
  }
};
</script>
